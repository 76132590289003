.app_strengths{
    background-color: var(--color-black);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px;
    width: 100%;
}

.app_strengths-heading{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 40px;
    width: 100%;
}

.app_strengths-heading h2{
    color: var(--color-white);
    font-size: 52px;
    font-weight: 480;
}

.app_strengths-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
}

@media screen and (max-width: 2992px) {
    .app_strengths-container{
        justify-content: center;
    }
}

@media screen and (max-width: 1500px){
    .app_strengths-container{
        justify-content: center;
    }   
}

@media screen and (max-width: 840px) {
    .app_strengths-container{
        
    }
    .app_strengths-heading h2{
        font-size: 40px;
        font-weight: 440;
    }.app_strengths-heading{
        margin-top: 10px;
        margin-bottom: 30px;
        width: 100%;
    }
}

@media screen and (max-width: 600px){
    .app_strengths{
        margin-top: 40px;
        padding: 20px;
    }
    .app_strengths-heading h2{
        font-size: 32px;
    }
}