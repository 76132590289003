.container {
  background-color: var(--color-black);
  max-width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.heroContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 60px;
}

.heroCrousal {
  width: 380px;
  margin: 20px 50px;
  display: flex;
  justify-content: center;
  text-align: center;
  /* background-color: green; */
  border-radius: 20px;
  overflow: hidden;
  border: 5px solid lightgrey;
}

.heroCrousal img {
  object-fit: cover;
  width: 380px;
  height: 300px;
  background-color: green;
  overflow: hidden;
}

.heroText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  color: lightgrey;
  padding: 20px 50px;
  text-align: justify;
}

.heroText h1 {
  padding-bottom: 30px;
}

/* product section */

.productContainer {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
  border-radius: 40px;
  padding: 30px 0;
  background-color: rgb(31, 31, 31);
}
.productContainer a{
  text-decoration: none;
}

.productCard {
  color: lightgrey;
  display: flex;
  flex-direction: column;
  width: 250px;
  height: 250px;
  background-color: rgb(41, 41, 41);
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
}

.productCard img{
  width: 100%;
  height: 80%;
  object-fit: cover;
}

.productCard:hover {
  transform: scale(1.1);
  transition: 0.3s ease-in-out;
}

.cardText {
  padding: 5px;
}
.cardText h4{
  
  text-decoration: none;
  color: white;
  cursor: pointer;
  text-align: center;
  
}
.cardText:hover a{
  color:green
}

/* Item Hero */
.itemHeroContainer {
  display: flex;
}

/* Item pages */

.itemProductContainer {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
  border-radius: 40px;
  padding: 30px 0;
  width: 80%;
  margin: 50px 0;
  background-color: rgb(31, 31, 31);
}

.itemProductContainer a.items {
  text-decoration: none;
  color: lightgray;
  background-color: rgb(41, 41, 41);
  padding: 10px 20px;
  border-radius: 10px;
  text-align: center;
}

.itemProductContainer a.items:hover {
  transform: scale(1.3);
  transition: 0.3s ease-in-out;
  color: green;
}

.itemProductContainer a.items:active {
  transform: scale(1.3);
  transition: 0.3s ease-in-out;
  color: green;
}

@media only screen and (max-width: 1200px) {
  .heroContainer {
    flex-direction: column;
    align-items: center;
  }
  .heroText {
    width: 80vw;
  }
  .productCard {
    margin: 30px 0;
  }
  .itemProductContainer a.items {
    margin: 20px;
  }
}

@media only screen and (max-width: 620px) {
  .heroCrousal {
    width: 300px;
    height: 210px;
  }
  .productContainer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    padding: 30px 30px;
  }

  .heroContainer {
    padding: 10px;
  }

  .heroText {
    padding: 20px 20px;
  }
}

@media only screen and (max-width: 400px) {
  .heroCrousal {
    width: 200px;
    height: 150px;
  }
}
