*{
    font-family: var(--font-base);
    color: var(--color-white);
}

.app_strength{
    width: 19%;
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.1);
    opacity: 0.8px;
    margin: 20px 40px;
    border-radius: 10px;
    transition: all .2s ease-in-out
}

.app_strength-image{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
    overflow: hidden;
    width: 100%;
    padding: 0;
}

.app_strength-image img{
    width: 100%;
    height: 200px;
     border-top-right-radius: 10px;
    border-top-left-radius: 10px; 
     
}

.app_strength-content h4{
    font-size: 20px;
    font-weight: 460;
    line-height: 20px;
    margin-bottom: 16px;
    text-align: center;
}

.app_strength-content p{
    color: gray;
    line-height: 20px;
    text-align: justify;
    word-spacing: 0.1px;
    font-size: 15px;
    padding: 10px;
}

.app_strength:hover{
    transform: scale(1.2);
    background-color: rgba(59, 60, 59, 0.3);
}

@media screen and (min-width: 1950px) {
    .app_strength{
        width: 12%;
    }
}

@media screen and (max-width: 1500px){
    .app_strength{
        width: 30%;
        margin: 40px;
    }
    
    .app_strength-content p{
        color: lightgray;
        line-height: 24px;
        font-size: 16px;
    }
}



@media screen and (max-width: 930px){
    .app_strength{
        width: 35%;
    }
    .app_strength-image img{
        width: 280px;
        height: 240px;
    }
    .app_strength-content p{
        color: lightgray;
        line-height: 24px;
        font-size: 20px;
    }
    .app_strength-content h4{
        font-size: 28px;
        font-weight: 400;
        line-height: 36px;
        margin-bottom: 16px;
    }
    .app_strength-image{
        padding: 10px;
    }
}

@media screen and (max-width: 840px){
    .app_strength{
        width: 50%;
    }
    .app_strength-image img{
        width: 390px;
        height: 220px;
    }
    .app_strength-content p{
        color: lightgray;
        line-height: 24px;
        font-size: 20px;
    }
    .app_strength-content h4{
        font-size: 28px;
        font-weight: 400;
        line-height: 36px;
        margin-bottom: 16px;
    }
    .app_strength-image{
        padding: 10px;
    }
}


@media screen and (max-width: 540px){
    .app_strength{
        width: 60%;
        margin: 30px;
    }

    .app_strength-image{
        padding: 0;
        margin: 0;
        margin-bottom: 20px;
    }

    .app_strength-image img{
        width: 100%;
        height: 160px;
    }
    .app_strength-content p{
        color: lightgray;
        line-height: 20px;
        font-size: 12px;
    }
    .app_strength-content h4{
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        margin-bottom: 12px;
    }
}



