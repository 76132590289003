.app__video {
    height: 100vh;
}

.app__video video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.app__video-overlay {
    position: absolute;
    inset: 0;
    background: rgba(0,0,0,0.65);
    /* height:120vh; */
}

.content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100vh;
}

.text{
    color: white;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    height: 100vh;
    width: 52%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.text h1{
    font-size: 42px;
    font-weight: 500;
}

@media screen and (max-width: 800px){
    .text h1{
        font-size: 32px;
        font-weight: 460;
    }
    .text{
        font-size: 12px;
        width: 64%;
    }
    .app__video-overlay {
        position: absolute;
        inset: 0;
        background: rgba(0,0,0,0.65);
        height: 100vh;
        /* height:120vh; */
    }
}

.display{
    display: none;
}