.app_AboutUs{
    font-family: var(--font-base);
    width: 100%;
}

#features{
    display: flex;
    justify-content: space-around;
    padding: 100px;
    flex-wrap: wrap;
    /* margin-top: 40px; */
}

#features .fe-box{
    text-align: center;
    padding: 20px;
    border: 1px solid #cce7d0;
    border-radius: 4px;
    transition: all ease-in-out 0.2s;
}

#features .fe-box h6{
    margin-top: 30px;
    padding: 8px;
    background-color: #fddde4;
    color: #088178;
    border-radius: 4px;
    font-weight: 400;
    font-size: 16px;
    box-shadow: 20px 20px 44px rgba(56, 55, 55, 0.03);
}

#features .fe-box:hover{
    box-shadow: 10px 10px 54px rgba(70, 64, 221, 0.1);
    transform: scale(1.1);
}

#features .fe-box:nth-child(2) h6{
    background-color: #cdebbc;
}

#features .fe-box:nth-child(3) h6{
    background-color: #d1e8f2;
}

#features .fe-box:nth-child(4) h6{
    background-color: #cdd4f8;
}

#features .fe-box:nth-child(5) h6{
    background-color: #f6dbf6;
}


#page-header.about-header{
    background-image: url("/public/images/about.png");
    height: 45vh;
    background-repeat: no-repeat;
    background-position:0px 40%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    /* margin: 100px 0px; */
    margin-bottom: 40px;
}

#about-head{
    display:flex;
    align-items:center;
    padding: 20px 80px;
    margin-bottom: 30px;
}
#about-head div{
    padding-left:40px;
}
#about-head div p{
    color: rgb(122, 122, 122);
}
#about-head img{
    width:50%;
    border-radius: 4px;
}
    
#about-head div{
    padding-left:40px;
}
#about-head h2{
    color: white;
}


.mission{
    padding: 0px 100px;
    margin-top: 80px;
}

.mission h2{
    color: white;
    font-size: 42px;
}

.mission p{
    font-size: 24px;
    color: rgb(122, 122, 122);
}

@media (min-width: 480px) and (max-width: 850px){
    #about-head{
        flex-direction: column;
        justify-content: center;
        padding: 20px 40px;
    }
    #about-head img{
        width: 90%;
        margin-bottom: 40px;
    } 
    #about-head h2{
        font-size: 40px;
    }
    #about-head div p{
        font-size: 20px;
    } 
    #features.f-about{
        padding:20px 60px;
    }
    #features.f-about .fe-box{
        margin: 10px 0px;
    }
    .mission{
        padding: 0px 40px;
    }
}

@media (max-width: 480px){
    #about-head{
        flex-direction: column;
        justify-content: center;
        padding: 20px 20px;
    }
    #about-head img{
        width: 90%;
        margin-bottom: 20px;
    } 
    #about-head h2{
        font-size: 32px;
    }
    #about-head div p{
        font-size: 16px;
    }
    .mission{
        padding: 0px 40px;
    }
    .fe-box{
        margin-bottom: 10px;
    }
}